@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticond976.ttf?4f7700d6618cd0ef11fa9dc7cf3a6fc7") format("truetype"),
url("../fonts/flaticond976.woff?4f7700d6618cd0ef11fa9dc7cf3a6fc7") format("woff"),
url("../fonts/flaticond976.woff2?4f7700d6618cd0ef11fa9dc7cf3a6fc7") format("woff2"),
url("../fonts/flaticond976.eot?4f7700d6618cd0ef11fa9dc7cf3a6fc7#iefix") format("embedded-opentype"),
url("../fonts/flaticond976.svg?4f7700d6618cd0ef11fa9dc7cf3a6fc7#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-search:before {
    content: "\f101";
}
.flaticon-paper-plane:before {
    content: "\f102";
}
.flaticon-college:before {
    content: "\f103";
}
.flaticon-bubble-chat:before {
    content: "\f104";
}
.flaticon-blueprint:before {
    content: "\f105";
}
.flaticon-star-1:before {
    content: "\f106";
}
.flaticon-school:before {
    content: "\f107";
}
.flaticon-athletics:before {
    content: "\f108";
}
.flaticon-web-development:before {
    content: "\f109";
}
.flaticon-audio-speaker:before {
    content: "\f10a";
}
.flaticon-connection:before {
    content: "\f10b";
}
.flaticon-clock:before {
    content: "\f10c";
}
.flaticon-share:before {
    content: "\f10d";
}
.flaticon-money-exchange:before {
    content: "\f10e";
}
.flaticon-advertising:before {
    content: "\f10f";
}
.flaticon-pdf-file:before {
    content: "\f110";
}
.flaticon-back:before {
    content: "\f111";
}
.flaticon-next:before {
    content: "\f112";
}
.flaticon-time:before {
    content: "\f113";
}
.flaticon-profile:before {
    content: "\f114";
}
.flaticon-writing-tool:before {
    content: "\f115";
}
.flaticon-heartbeat:before {
    content: "\f116";
}
.flaticon-arrow-down-sign-to-navigate:before {
    content: "\f117";
}
.flaticon-student-with-graduation-cap:before {
    content: "\f118";
}
.flaticon-college-graduation:before {
    content: "\f119";
}
.flaticon-tag:before {
    content: "\f11a";
}
.flaticon-phone-call:before {
    content: "\f11b";
}
.flaticon-repeat:before {
    content: "\f11c";
}
.flaticon-user:before {
    content: "\f11d";
}
.flaticon-briefcase:before {
    content: "\f11e";
}
.flaticon-information:before {
    content: "\f11f";
}
.flaticon-pc:before {
    content: "\f120";
}
.flaticon-email:before {
    content: "\f121";
}
.flaticon-auction:before {
    content: "\f122";
}
.flaticon-winning-runner:before {
    content: "\f123";
}
.flaticon-pin:before {
    content: "\f124";
}
.flaticon-help:before {
    content: "\f125";
}
.flaticon-fashion:before {
    content: "\f126";
}
.flaticon-twitter:before {
    content: "\f127";
}
.flaticon-facebook:before {
    content: "\f128";
}
.flaticon-instagram:before {
    content: "\f129";
}
.flaticon-linkedin:before {
    content: "\f12a";
}
.flaticon-graduation:before {
    content: "\f12b";
}
.flaticon-messenger:before {
    content: "\f12c";
}
.flaticon-art:before {
    content: "\f12d";
}
