/*


*******************************************
*******************************************

** - Default-Style
** - TopHeader-style
** - Navbar-Style
** - Preloader-Style
** - Sidebar-Style
** - Banner-Style
** - Academics-Style
** - Education-Style
** - Campus-Information-Style
** - Courses-Style
** - Campus-Life-Style
** - Admission-Style
** - Program-Style
** - Health-Care-Style
** - Management-Style
** - Podcasts-Style
** - Stories-Style
** - Latest-News-Style
** - Page-Banner-Style
** - Student life-Style
** - Alumni-Style
** - Contact-Us-Style
** - Faq Area-Style
** - User-Style
** - Privacy-Policy-Style
** - Terms-Condition-Style
** - Error-Style
** - Coming-Soon-Style
** - Footer-Style
*/
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");
/*Default-style
======================================================*/
body {
  color: #555555;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-20 {
  margin-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mlr-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mlrt-5 {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
}

h1, h2, h3, h4, h5, h6 {
  color: #111d5e;
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
}

p {
  margin-bottom: 15px;
  font-size: 15px;
  color: #555555;
  line-height: 1.7;
}
p:last-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.owl-nav {
  margin-top: 0 !important;
}

.owl-dots {
  margin-top: 0 !important;
}

.form-control {
  height: 55px;
  border: 1px solid rgba(89, 90, 89, 0.25);
  padding-left: 15px;
}
.form-control:focus {
  border: 1px solid #e32845;
  box-shadow: none;
}

textarea {
  height: auto !important;
}

.bg-f4f6f9 {
  background-color: #f4f6f9;
}

/*
Top TopHeader Area Style
======================================================*/
.top-header-area {
  background-color: #e32845;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-header-area .container-fluid {
  max-width: 1520px;
}

.header-left-content p {
  color: #ffffff;
  font-size: 14px;
}

.header-right-content {
  text-align: right;
}
.header-right-content .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.header-right-content .list ul li {
  display: inline-block;
  margin-right: 25px;
}
.header-right-content .list ul li a {
  color: #ffffff;
  font-size: 14px;
}
.header-right-content .list ul li:last-child {
  margin-right: 0;
}

.buy-now-btn {
  right: 20px;
  z-index: 99;
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  box-shadow: 0 1px 20px 1px #82b440;
  font-size: 13px;
  font-weight: 600;
}
.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.buy-now-btn:hover {
  background-color: #e32845;
  color: #ffffff;
}

/*
Navbar Area Style
======================================================*/
.navbar-area {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar-area.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: sticky;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.navbar-area .container-fluid {
  max-width: 1520px;
  margin: auto;
}
.navbar-area .navbar-brand .white-logo {
  display: none;
}
.navbar-area.nav-bg-2 .desktop-nav .navbar-nav .nav-item a {
  color: #111d5e;
}
.navbar-area.nav-bg-2 .desktop-nav .others-options .icon {
  color: #111d5e;
}

.nav-bg-1 {
  background-color: #111d5e;
}

.nav-bg-2 {
  background-color: #ffffff;
}

.desktop-nav {
  padding-top: 0;
  padding-bottom: 0;
}
.desktop-nav .navbar {
  transition: all ease 0.5s;
  z-index: 2;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.desktop-nav .navbar ul {
  margin-bottom: 0;
  list-style-type: none;
}
.desktop-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 0;
  padding-left: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item a {
  font-size: 16px;
  color: #ffffff;
  line-height: 1;
  position: relative;
  font-weight: 600;
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle {
  padding-right: 20px;
}
.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::after {
  display: none;
}
.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::before {
  content: "\ea4e";
  position: absolute;
  top: 0;
  right: 0;
  font-family: remixicon !important;
  line-height: 1;
  font-size: 17px;
}
.desktop-nav .navbar .navbar-nav .nav-item a:hover, .desktop-nav .navbar .navbar-nav .nav-item a:focus, .desktop-nav .navbar .navbar-nav .nav-item a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item a i {
  font-size: 18px;
  line-height: 0;
  position: relative;
  top: 4px;
}
.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item:hover a, .desktop-nav .navbar .navbar-nav .nav-item:focus a, .desktop-nav .navbar .navbar-nav .nav-item.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  position: absolute;
  visibility: hidden;
  border-radius: 0;
  display: block;
  width: 250px;
  border: none;
  z-index: 99;
  opacity: 0;
  top: 80px;
  left: 0;
  padding: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #1b2336;
  padding: 15px;
  border-bottom: 1px dashed #ededed;
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  display: block;
  position: relative;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::after {
  display: none;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::before {
  content: "\ea4e";
  position: absolute;
  top: 14px;
  right: 13px;
  font-family: remixicon !important;
  line-height: 1;
  font-size: 17px;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  right: 250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #1b2336;
  border-bottom: 1px dashed #ededed;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  position: relative;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::after {
  display: none;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::before {
  content: "\ea4e";
  position: absolute;
  top: 14px;
  right: 13px;
  font-family: remixicon !important;
  line-height: 1;
  font-size: 17px;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: 250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: -250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: -250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: -250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: -250px;
  left: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1b2336;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1b2336;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -1px;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -1px;
  visibility: visible;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #e32845;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 0;
}
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  top: 100%;
  opacity: 1;
  margin-top: 20px;
  transform: translateY(1);
  visibility: visible;
}
.desktop-nav .navbar .others-options {
  padding-left: 40px;
}
.desktop-nav .navbar .others-options .icon {
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
}

/*
Others Option For Responsive Area Style
======================================================*/
.others-option-for-responsive {
  display: none !important;
}
.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  right: 60px;
  top: -43px;
}
.others-option-for-responsive .dot-menu .inner {
  display: flex;
  align-items: center;
  height: 30px;
}
.others-option-for-responsive .dot-menu .inner .icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  background-color: #e32845;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
}
.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: #e32845;
}
.others-option-for-responsive .container {
  position: relative;
}
.others-option-for-responsive .container .container {
  position: absolute;
  right: -2px;
  top: 15px;
  max-width: 320px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.5s;
  transform: scaleY(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}
.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.others-option-for-responsive .others-options {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 10px;
  background-color: #ffffff;
  text-align: center;
}
.others-option-for-responsive .others-options ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  align-items: center !important;
}
.others-option-for-responsive .others-options ul li {
  display: inline-block;
}
.others-option-for-responsive .others-options ul li .call-now {
  position: relative;
  padding-left: 60px;
  top: 8px;
  margin-bottom: 40px !important;
}
.others-option-for-responsive .others-options ul li .call-now i {
  position: absolute;
  font-size: 40px;
  color: #e32845;
  left: 0;
  top: 3px;
}
.others-option-for-responsive .others-options ul li .call-now p {
  margin-bottom: 8px;
}
.others-option-for-responsive .others-options ul li .call-now a {
  color: #111d5e;
  font-weight: 500;
}
.others-option-for-responsive .others-options ul li .default-btn {
  bottom: 9px;
}

.mobile-responsive-nav {
  display: none;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
/*
Preloader Style
======================================================*/
.preloader-area {
  position: fixed;
  z-index: 999999;
  background-color: #e32845;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
}
.preloader-area .spinner {
  width: 4em;
  height: 4em;
  transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-45%);
  margin-left: auto;
  margin-right: auto;
}
.preloader-area .spinner .disc {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.3em dotted #ffffff;
}
.preloader-area .spinner .disc:nth-child(1) {
  animation: rotate 12s linear infinite;
}
.preloader-area .spinner .disc:nth-child(2) {
  animation: rotateDisc2 12s linear infinite;
}
.preloader-area .spinner .disc:nth-child(3) {
  animation: rotateDisc3 12s linear infinite;
}
.preloader-area .spinner .inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: sphereSpin 6s linear infinite;
}
.preloader-area .spinner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  border: 2px dotted #ffffff;
  margin: -15px;
}

@keyframes sphereSpin {
  0% {
    transform: rotateX(360deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(0deg) rotateY(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotateDisc2 {
  from {
    transform: rotateX(90deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(90deg) rotateZ(360deg);
  }
}
@keyframes rotateDisc3 {
  from {
    transform: rotateY(90deg) rotateZ(0deg);
  }
  to {
    transform: rotateY(90deg) rotateZ(360deg);
  }
}
/*Sidebar Modal
======================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
}
.modal.right .modal-content button.close {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: transparent;
  border: none;
  text-shadow: unset;
  box-shadow: unset;
  z-index: 99;
  opacity: 1;
}
.modal.right .modal-content button.close i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #e32845;
  color: #ffffff;
  font-size: 20px;
  transition: all ease 0.5s;
  border-radius: 50%;
}
.modal.right .modal-content button.close i:hover {
  background-color: #111d5e;
}
.modal.right.fade .modal-dialog {
  right: -320px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
  right: 0;
}

.sidebarModal.modal .modal-body {
  padding: 25px;
}
.sidebarModal.modal .modal-body .white-logo {
  display: none;
}
.sidebarModal.modal .modal-body .sidebar-content {
  margin-top: 35px;
  margin-bottom: 40px;
}
.sidebarModal.modal .modal-body .sidebar-content p {
  margin-bottom: 40px;
}
.sidebarModal.modal .modal-body .sidebar-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
.sidebarModal.modal .modal-body .sidebar-content .sidebar-btn {
  margin-top: 25px;
}
.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 30px;
}
.sidebarModal.modal .modal-body .sidebar-contact-info h3 {
  font-size: 22px;
  margin-bottom: 0;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
  padding-left: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
  list-style-type: none;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  padding-left: 22px;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li:last-child {
  margin-bottom: 0;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a {
  color: #555555;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a:hover {
  color: #e32845;
}
.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #e32845;
}
.sidebarModal.modal .modal-body .sidebar-social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}
.sidebarModal.modal .modal-body .sidebar-social-list li {
  display: inline-block;
  margin-right: 8px;
}
.sidebarModal.modal .modal-body .sidebar-social-list li:last-child {
  margin-right: 0;
}
.sidebarModal.modal .modal-body .sidebar-social-list li i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 40px;
  font-size: 16px;
  background: #e32845;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  transition: all ease 0.5s;
}
.sidebarModal.modal .modal-body .sidebar-social-list li i:hover {
  transform: translateY(-5px);
  background-color: #111d5e;
  color: #ffffff;
}
.sidebarModal.modal .modal-body .contact-form {
  margin-top: 30px;
}
.sidebarModal.modal .modal-body .contact-form h3 {
  font-size: 22px;
  margin-bottom: 25px;
}
.sidebarModal.modal .modal-body .contact-form #contactForm .form-group {
  margin-bottom: 20px;
}
.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control {
  padding: 15px;
}
.sidebarModal.modal .modal-body .contact-form #contactForm .default-btn {
  border: none;
}
.sidebarModal.modal .modal-body .contact-form #contactForm .list-unstyled {
  color: #ee1010;
  font-size: 14px;
  margin-top: 8px;
}
.sidebarModal.modal .modal-body .contact-form #contactForm #msgSubmit {
  font-size: 15px;
}

/*Default-btn
======================================================*/
.default-btn {
  background-color: #e32845;
  color: #ffffff;
  font-size: 16px;
  padding: 13px 35px;
  border-radius: 0;
  position: relative;
  transition: all ease 0.5s;
  border: 1px solid #e32845;
  z-index: 1;
}
.default-btn i {
  position: relative;
  top: 3px;
  padding-left: 5px;
}
.default-btn::before {
  position: absolute;
  content: "";
  background-color: #111d5e;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transition: all ease 0.5s;
  z-index: -1;
}
.default-btn:hover {
  color: #ffffff;
}
.default-btn:hover::before {
  transform: scaleX(1);
}
.default-btn:focus {
  box-shadow: none;
}

.read-more-btn {
  color: #111d5e;
  font-weight: 500;
  font-size: 15px;
  transition: all ease 0.5s;
  position: relative;
}
.read-more-btn i {
  position: relative;
  top: 3px;
  padding-left: 7px;
}
.read-more-btn:hover {
  color: #e32845;
}
.read-more-btn.active {
  color: #e32845;
  letter-spacing: 0;
}
.read-more-btn.white-color {
  color: #ffffff;
}
.read-more-btn.white-color:hover {
  color: #e32845;
}

/*Section-title
======================================================*/
.section-title {
  max-width: 450px;
  text-align: center;
  margin: -10px auto 40px;
  position: relative;
}
.section-title.style2 {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}
.section-title h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
.section-title h2.white {
  color: #ffffff;
}
.section-title p {
  margin-bottom: 20px;
}
.section-title p:last-child {
  margin-bottom: 0;
}
.section-title.white-title .top-title {
  color: #ffffff;
}
.section-title.white-title h2 {
  color: #ffffff;
}
.section-title.white-title p {
  color: #ffffff;
}

/*
Banner Style
======================================================*/
.banner-area {
  background-color: #111d5e;
  overflow: hidden;
}
.banner-area .container-fluid {
  padding: 0;
  padding-left: 30px;
  max-width: 1720px;
  margin-right: 0;
  margin-left: auto;
}

.slider-item {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 200px;
  padding-left: 60px;
  overflow: hidden;
}
.slider-item.banner-bg-1 {
  background-image: url(../images/banner/banner-img1.jpg);
}
.slider-item.banner-bg-2 {
  background-image: url(../images/banner/banner-img2.jpg);
}
.slider-item.banner-bg-3 {
  background-image: url(../images/banner/banner-img3.jpg);
}
.slider-item.banner-bg-4 {
  background-image: url(../images/banner/banner-img4.jpg);
}
.slider-item.banner-bg-5 {
  background-image: url(../images/banner/banner-img5.jpg);
}
.slider-item.banner-bg-6 {
  background-image: url(../images/banner/banner-img6.jpg);
}
.slider-item.banner-bg-7 {
  background-image: url(../images/banner/banner-img7.jpg);
}
.slider-item.banner-bg-8 {
  background-image: url(../images/banner/banner-img8.jpg);
}

.slider-content {
  max-width: 740px;
  margin-left: 0;
  margin-right: auto;
}
.slider-content h1 {
  font-size: 65px;
  color: #ffffff;
  margin-bottom: 20px;
}
.slider-content p {
  color: #ffffff;
  margin-bottom: 30px;
}
.slider-content.style2 {
  background-color: #ffffff;
  padding: 50px;
}
.slider-content.style2 h1 {
  color: #111d5e;
}
.slider-content.style2 p {
  color: #555555;
}

.hero-slider {
  position: relative;
}
.hero-slider.owl-theme .owl-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
}
.hero-slider.owl-theme .owl-dots .owl-dot {
  display: block;
}
.hero-slider.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 45px;
  margin: 5px 0;
  background: transparent;
  display: block;
  transition: 0.5s;
  border-radius: 50%;
  position: relative;
  color: #ffffff;
}
.hero-slider.owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  content: "01";
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
.hero-slider.owl-theme .owl-dots .owl-dot:hover span, .hero-slider.owl-theme .owl-dots .owl-dot.active span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.hero-slider.owl-theme .owl-dots .owl-dot:hover span::after, .hero-slider.owl-theme .owl-dots .owl-dot.active span::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 40px;
  background-color: #ffffff;
  left: -30px;
  top: 22px;
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(2) span::before {
  content: "02";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(3) span::before {
  content: "03";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(4) span::before {
  content: "04";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(5) span::before {
  content: "05";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(6) span::before {
  content: "06";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(7) span::before {
  content: "07";
}
.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(8) span::before {
  content: "08";
}
.hero-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: 30px;
  bottom: -130px;
  transform: translateY(-50%);
  background-color: transparent;
  border: 1px solid #eeeeee;
  color: #ffffff;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.hero-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.hero-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: 100px;
}
.hero-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.hero-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}

.hero-slider2 .slider-item {
  padding-left: 0;
}
.hero-slider2.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
  opacity: 0;
  transition: all ease 0.5s;
}
.hero-slider2.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
  color: #ffffff;
}
.hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
  right: 100px;
  left: auto;
}
.hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.hero-slider2.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.hero-slider2:hover.owl-theme .owl-nav [class*=owl-] {
  opacity: 1;
}
.hero-slider2.style2.owl-theme .owl-nav [class*=owl-] {
  background: rgba(0, 0, 0, 0.15);
}
.hero-slider2.style2.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
}

.owl-thumbs {
  text-align: right;
  position: relative;
  margin-top: -75px;
  z-index: 1;
}
.owl-thumbs button {
  padding: 0;
  border: none;
  transition: all ease 0.5s;
}
.owl-thumbs button:hover, .owl-thumbs button.active {
  filter: blur(2px);
}
.owl-thumbs .owl-thumb-item {
  width: 200px;
  height: 150px;
}

/**/
.banner-area.banner-area-five {
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 1;
}
.banner-area.banner-area-five::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to left, rgba(9, 0, 51, 0), rgb(8, 1, 77));
}
.banner-area.banner-area-five video {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -2;
}
.banner-area.banner-area-five .slider-item {
  position: relative;
  padding-left: 175px;
  overflow: hidden;
  z-index: 3;
}

/*
Academics Style
======================================================*/
.single-academics-card {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-academics-card .academic-top-content {
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
}
.single-academics-card .academic-top-content h3 {
  font-size: 22px;
  transition: all ease 0.5s;
}
.single-academics-card .academic-top-content h3:hover {
  color: #e32845;
}
.single-academics-card .academic-top-content i {
  font-size: 30px;
  position: absolute;
  color: #e32845;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-academics-card:hover {
  transform: translateY(-10px);
}

.single-academics-card2 {
  background-size: cover;
  background-position: center center;
  height: 500px;
  padding: 30px;
  position: relative;
  margin-bottom: 30px;
}
.single-academics-card2::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, rgba(17, 29, 94, 0.9));
  left: 0;
  top: 0;
}
.single-academics-card2.bg-1 {
  background-image: url(../images/academics/academic-1.jpg);
}
.single-academics-card2.bg-2 {
  background-image: url(../images/academics/academic-2.jpg);
}
.single-academics-card2.bg-3 {
  background-image: url(../images/academics/academic-3.jpg);
}
.single-academics-card2 .serial {
  position: absolute;
  left: 30px;
  top: 30px;
}
.single-academics-card2 .serial p {
  color: #ffffff;
  font-size: 18px;
}
.single-academics-card2 .academic-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
}
.single-academics-card2 .academic-content .academic-top-content {
  position: relative;
  padding-left: 30px;
}
.single-academics-card2 .academic-content .academic-top-content h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 15px;
  transition: all ease 0.5s;
}
.single-academics-card2 .academic-content .academic-top-content h3:hover {
  color: #e32845;
}
.single-academics-card2 .academic-content .academic-top-content i {
  color: #e32845;
  font-size: 20px;
  position: absolute;
  left: 0;
}
.single-academics-card2 .academic-content p {
  color: #ffffff;
  margin-bottom: 15px;
}

.single-academics-card3 {
  text-align: center;
  padding: 25px;
  border: 1px solid #eeeeee;
  margin-bottom: 30px;
  position: relative;
}
.single-academics-card3::before {
  position: absolute;
  content: "";
  height: 100%;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background-color: #e32845;
  transition: all ease 0.5s;
}
.single-academics-card3 .icon {
  margin-bottom: 20px;
}
.single-academics-card3 .icon i {
  font-size: 35px;
  height: 65px;
  width: 65px;
  line-height: 72px;
  border-radius: 100%;
  border: 1px solid #e32845;
  display: inline-block;
  color: #e32845;
  position: relative;
  transition: all ease 0.5s;
}
.single-academics-card3 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  position: relative;
  transition: all ease 0.5s;
}
.single-academics-card3 p {
  position: relative;
  transition: all ease 0.5s;
}
.single-academics-card3:hover::before {
  width: 100%;
  border-radius: 0;
  left: 0;
  right: auto;
}
.single-academics-card3:hover .icon i {
  color: #ffffff;
  border-color: #ffffff;
  transform: rotate(45deg);
}
.single-academics-card3:hover h3 {
  color: #ffffff;
}
.single-academics-card3:hover p {
  color: #ffffff;
}
.single-academics-card3:hover .read-more-btn {
  color: #ffffff;
}

.serch-content {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.serch-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
.serch-content .form-group {
  position: relative;
}
.serch-content .form-group .form-control {
  border: none;
  border: 1px solid transparent;
}
.serch-content .form-group .form-control:focus {
  border: 1px solid #e32845;
}
.serch-content .form-group .src-btn {
  position: absolute;
  right: 10px;
  top: 13px;
  font-size: 20px;
  background-color: transparent;
  border: none;
}

.category-content {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.category-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
.category-content .form-control {
  border: none;
  border: 1px solid transparent;
}
.category-content .form-control:focus {
  border: 1px solid #e32845;
}

.location-type {
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.location-type h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.location-type .form-check .form-check-input {
  border-radius: 0;
}
.location-type .form-check .form-check-input:checked {
  background-color: #e32845;
  border-color: #e32845;
}
.location-type .form-check .form-check-input:focus {
  box-shadow: none;
}

.program-level {
  margin-bottom: 30px;
}
.program-level h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.program-level .form-check .form-check-input {
  border-radius: 0;
}
.program-level .form-check .form-check-input:checked {
  background-color: #e32845;
  border-color: #e32845;
}
.program-level .form-check .form-check-input:focus {
  box-shadow: none;
}

.academics-details .details h2 {
  font-size: 36px;
  margin-bottom: 20px;
}
.academics-details .details p {
  margin-bottom: 20px;
}
.academics-details .details .details-images {
  margin-top: 10px;
  display: inline-block;
}
.academics-details .details .details-images img {
  margin-bottom: 30px;
}
.academics-details .regiStartion-and-fee {
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.academics-details .regiStartion-and-fee h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.academics-details .regiStartion-and-fee p {
  margin-bottom: 20px;
}
.academics-details .regiStartion-and-fee .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.academics-details .regiStartion-and-fee .list ul li {
  margin-bottom: 10px;
  list-style-type: none;
}
.academics-details .regiStartion-and-fee .list ul li:last-child {
  margin-bottom: 0;
}
.academics-details .prepare-exam h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.academics-details .prepare-exam p {
  margin-bottom: 30px;
}
.academics-details .prepare-exam .exam-card {
  margin-bottom: 30px;
  padding-left: 30px;
}
.academics-details .prepare-exam .exam-card h4 {
  font-size: 20px;
  margin-bottom: 15px;
  position: relative;
}
.academics-details .prepare-exam .exam-card h4::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #e32845;
  left: -30px;
  top: 5px;
}
.academics-details .prepare-exam .exam-card p {
  margin-bottom: 0;
}

.academics-list {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.academics-list h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.academics-list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.academics-list ul li {
  list-style-type: none;
  position: relative;
  margin-bottom: 10px;
}
.academics-list ul li a {
  color: #555555;
  transition: all ease 0.5s;
}
.academics-list ul li i {
  position: absolute;
  right: 0;
  font-size: 22px;
}
.academics-list ul li:last-child {
  margin-bottom: 0;
}
.academics-list ul li:hover a {
  color: #e32845;
}

.related-download {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.related-download h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.related-download ul {
  padding-left: 0;
  margin-bottom: 0;
}
.related-download ul li {
  margin-bottom: 10px;
  list-style-type: none;
  background-color: #ffffff;
  padding: 20px;
  padding-left: 70px;
  position: relative;
}
.related-download ul li a {
  color: #555555;
}
.related-download ul li i {
  position: absolute;
  left: 0;
  background-color: #e32845;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 24px;
  color: #ffffff;
  padding: 16px;
}
.related-download ul li:last-child {
  margin-bottom: 0;
}

/*
Education Style
======================================================*/
.single-education-card {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 50px 30px;
  margin-bottom: 30px;
  z-index: 1;
}
.single-education-card.bg-1 {
  background-image: url(../images/academics/academic-4.jpg);
}
.single-education-card.bg-2 {
  background-image: url(../images/academics/academic-5.jpg);
}
.single-education-card.bg-3 {
  background-image: url(../images/academics/academic-6.jpg);
}
.single-education-card.bg-4 {
  background-image: url(../images/academics/academic-7.jpg);
}
.single-education-card .edication-content {
  text-align: center;
  position: relative;
}
.single-education-card .edication-content .icon i {
  font-size: 50px;
  margin-bottom: 10px;
  color: #ffffff;
}
.single-education-card .edication-content h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 15px;
}
.single-education-card .edication-content .read-more-btn:hover {
  color: #111d5e;
}
.single-education-card::before {
  position: absolute;
  content: "";
  background: rgba(17, 29, 94, 0.6);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.single-education-card::after {
  position: absolute;
  content: "";
  background: #e32845;
  display: inline-block;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  z-index: -1;
  transition: all ease 0.5s;
}
.single-education-card:hover::after {
  width: 100%;
  border-radius: 0;
  left: 0;
  right: auto;
}

/*
Campus Information Style
======================================================*/
.campus-content {
  margin-bottom: 30px;
}
.campus-content .campus-title {
  margin-bottom: 30px;
}
.campus-content .campus-title h2 {
  font-size: 40px;
  margin-bottom: 15px;
}
.campus-content .list {
  margin-bottom: 20px;
}
.campus-content .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.campus-content .list ul li {
  position: relative;
  padding-left: 20px;
  list-style-type: none;
  margin-bottom: 10px;
}
.campus-content .list ul li i {
  position: absolute;
  left: 0;
  color: #e32845;
}
.campus-content.style-2 {
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  margin-left: -100px;
}

.counter-card {
  margin-bottom: 30px;
}
.counter-card h1 {
  font-size: 60px;
  margin-bottom: 5px;
  line-height: 1;
}
.counter-card h1 .target {
  color: #e32845;
  position: relative;
  top: 4px;
}
.counter-card h1 .target.heading-color {
  color: #111d5e;
  font-size: 60px;
  margin-left: -4px;
}

.campus-image {
  margin-bottom: 30px;
}

.estemate-form {
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  margin-top: -200px;
  z-index: 1;
}
.estemate-form h3 {
  font-size: 30px;
  margin-bottom: 30px;
}
.estemate-form .form-group {
  margin-bottom: 25px;
}
.estemate-form .form-group .form-control {
  background-color: #f4f6f9;
  border: 1px solid #f4f6f9;
}
.estemate-form .form-group .form-control:focus {
  border: 1px solid #e32845;
}
.estemate-form .default-btn {
  display: block;
  width: 100%;
}

/*
Courses Style
======================================================*/
.single-courses-card {
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-courses-card .courses-content {
  background-color: #ffffff;
  padding: 30px;
}
.single-courses-card .courses-content .admin-profile {
  position: relative;
  padding-left: 80px;
  padding-bottom: 35px;
  margin-bottom: 20px;
}
.single-courses-card .courses-content .admin-profile img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: auto;
}
.single-courses-card .courses-content .admin-profile p a {
  color: #e32845;
  font-weight: 500;
}
.single-courses-card .courses-content .admin-profile::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
  right: -30px;
  bottom: 10px;
}
.single-courses-card .courses-content h3 {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 20px;
  transition: all ease 0.5s;
}
.single-courses-card .courses-content h3:hover {
  color: #e32845;
}
.single-courses-card .courses-content .bottom-content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.single-courses-card .courses-content .bottom-content ul li {
  list-style-type: none;
  font-weight: 500;
}
.single-courses-card .courses-content .bottom-content ul li a {
  color: #e32845;
}
.single-courses-card .courses-content .bottom-content ul li ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  padding-left: 22px;
}
.single-courses-card .courses-content .bottom-content ul li ul li i {
  position: absolute;
  left: 0;
  top: 0;
  color: #e32845;
}
.single-courses-card.style2 {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-courses-card.style2 .courses-content .admin-profile {
  padding-bottom: 0;
  padding-left: 0;
}
.single-courses-card.style2 .courses-content .admin-profile::before {
  display: none;
}
.single-courses-card.style2 .courses-content .admin-profile img {
  right: 0;
  left: auto;
  top: -60px;
}
.single-courses-card.style2:hover {
  transform: translateY(0);
}
.single-courses-card.style3 .list-1 {
  margin-bottom: 15px;
}
.single-courses-card.style3 .list-1 ul {
  padding-left: 0;
  margin-bottom: 0;
}
.single-courses-card.style3 .list-1 ul li {
  list-style-type: none;
}
.single-courses-card.style3 .list-1 ul li i {
  color: #e32845;
  margin-right: 7px;
}
.single-courses-card.style3 h3 {
  margin-bottom: 35px;
}
.single-courses-card.style3 .admin-profile {
  padding-bottom: 20px;
  margin-bottom: 45px;
}
.single-courses-card.style3 .admin-profile img {
  top: -15px;
}
.single-courses-card.style3 .admin-profile::before {
  right: 0;
  bottom: -30px;
}
.single-courses-card.style3 .bottom-content ul li ul li {
  margin-right: 0;
  color: #ffc107;
}
.single-courses-card.style3 .bottom-content ul li ul li.blank {
  color: #555555;
}
.single-courses-card.style3:hover {
  transform: translateY(0);
}
.single-courses-card.style4 .courses-content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-courses-card:hover {
  transform: translateY(-10px);
}

.courses-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.courses-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.courses-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.courses-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.courses-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.courses-slider.owl-theme .owl-dots {
  left: 0;
}
.courses-slider.owl-theme .owl-dots .owl-dot span {
  background-color: #e32845;
  margin: 10px 10px 0 10px;
  background-color: #dfdbdb;
  height: 18px;
  width: 18px;
}
.courses-slider.owl-theme .owl-dots .owl-dot.active span {
  position: relative;
}
.courses-slider.owl-theme .owl-dots .owl-dot.active span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e32845;
  border-radius: 100%;
  top: 4px;
  left: 4px;
}
.courses-slider.style-2.owl-theme .owl-dots {
  display: none;
}

.paginations {
  text-align: center;
  margin-bottom: 30px;
}
.paginations ul {
  padding-left: 0;
  margin-bottom: 0;
}
.paginations ul li {
  display: inline-block;
  padding-right: 10px;
}
.paginations ul li a {
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 100%;
  border: 1px solid #eeeeee;
  display: inline-block;
  transition: all ease 0.5s;
  color: #111d5e;
  font-size: 18px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.paginations ul li a i {
  position: relative;
  top: 2px;
}
.paginations ul li a:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.paginations ul li a.active {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}

.courses-details .courses-card {
  margin-bottom: 40px;
}
.courses-details .courses-card h2 {
  font-size: 36px;
  margin-bottom: 25px;
}
.courses-details .courses-card .img {
  margin-bottom: 50px;
}
.courses-details .courses-card .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.courses-details .courses-card .list ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 30px;
}
.courses-details .courses-card .list ul li .teacher {
  position: relative;
  padding-left: 70px;
}
.courses-details .courses-card .list ul li .teacher img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.courses-details .courses-card .list ul li .teacher p a {
  color: #e32845;
}
.courses-details .courses-card .list ul li span {
  color: #111d5e;
  font-weight: 500;
}
.courses-details .courses-card .list ul li i {
  color: #e32845;
  position: relative;
  top: 2px;
  padding-right: 7px;
}

.description {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.description .nav-tabs {
  font-size: 18px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border: none;
}
.description .nav-tabs .nav-link {
  color: #111d5e;
  padding-bottom: 10px;
  padding: 15px 30px;
  font-weight: 600;
  border: none;
  border-radius: 0;
}
.description .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #e32845 !important;
  border-radius: 0;
  border: none;
  background-color: transparent;
}

.overview .learn {
  margin-bottom: 30px;
}
.overview .learn h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.overview .learn p {
  margin-bottom: 20px;
}
.overview .overview-box {
  padding-left: 30px;
  margin-bottom: 30px;
}
.overview .overview-box h4 {
  font-size: 18px;
  position: relative;
}
.overview .overview-box h4::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #e32845;
  left: -30px;
  top: 5px;
}

.curriculum {
  margin-bottom: 30px;
}
.curriculum h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.curriculum p {
  margin-bottom: 20px;
}
.curriculum .curriculum-list {
  padding: 30px;
  background-color: #f4f6f9;
}
.curriculum .curriculum-list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.curriculum .curriculum-list ul li {
  list-style-type: none;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d5d0d0;
  position: relative;
}
.curriculum .curriculum-list ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.curriculum .curriculum-list ul li span {
  position: absolute;
  right: 0;
  top: -5px;
  background-color: #e32845;
  color: #ffffff;
  padding: 5px 10px;
}

.instructor {
  margin-bottom: 30px;
}
.instructor .instructor-content .name {
  margin-bottom: 25px;
}
.instructor .instructor-content .name h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.instructor .instructor-content .quick-contact {
  margin-bottom: 20px;
}
.instructor .instructor-content .quick-contact h4 {
  font-size: 20px;
  margin-bottom: 20px;
}
.instructor .instructor-content .quick-contact ul {
  padding-left: 0;
  margin-bottom: 0;
}
.instructor .instructor-content .quick-contact ul li {
  list-style-type: none;
}
.instructor .instructor-content .quick-contact ul li i {
  color: #e32845;
  position: relative;
  top: 2px;
  margin-right: 7px;
}
.instructor .instructor-content .quick-contact ul li a {
  color: #e32845;
}
.instructor .instructor-content .social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.instructor .instructor-content .social ul li {
  display: inline-block;
  list-style-type: none;
  margin-right: 10px;
}
.instructor .instructor-content .social ul li a {
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 7px;
  text-align: center;
  font-size: 18px;
  background-color: #e32845;
  color: #ffffff;
  display: inline-block;
  transition: all ease 0.5s;
}
.instructor .instructor-content .social ul li a:hover {
  background-color: #111d5e;
  color: #ffffff;
}

.review-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.review-form .form-group {
  margin-bottom: 30px;
}
.review-form .form-group label {
  mn: 7px;
}

.single-reviews-card {
  position: relative;
  padding: 30px;
  padding-left: 140px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.single-reviews-card .date {
  margin-bottom: 20px;
}
.single-reviews-card .icon {
  position: absolute;
  right: 30px;
  top: 60px;
  color: #ffc107;
}
.single-reviews-card h2 {
  font-size: 22px;
  margin-bottom: 10px;
}
.single-reviews-card img {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.comments {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.comments h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.comments .single-comments-box {
  padding-left: 110px;
  position: relative;
  margin-bottom: 30px;
}
.comments img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.comments .reply {
  position: absolute;
  right: 0;
  background-color: #f4f6f9;
  padding: 10px 15px;
  top: 15px;
  transition: all ease 0.5s;
}
.comments .reply a {
  color: #555555;
  transition: all ease 0.5s;
}
.comments .reply:hover {
  background-color: #e32845;
}
.comments .reply:hover a {
  color: #ffffff;
}
.comments h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.comments .date {
  margin-bottom: 20px;
}

.reply-area {
  margin-bottom: 30px;
}
.reply-area h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.reply-area p {
  margin-bottom: 20px;
}
.reply-area .reply-form .form-group {
  margin-bottom: 25px;
}
.reply-area .reply-form .form-group .form-control {
  background-color: #f4f6f9;
  border: 1px solid transparent;
}
.reply-area .reply-form .form-group .form-control:focus {
  border-color: #e32845;
}
.reply-area .reply-form .form-check {
  margin-bottom: 30px;
}
.reply-area .reply-form .form-check .form-check-input {
  border-radius: 0;
}
.reply-area .reply-form .form-check .form-check-input:checked {
  background-color: #e32845;
  border-color: #e32845;
}
.reply-area .reply-form .form-check .form-check-input:focus {
  box-shadow: none;
}

.enroll-courses {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.enroll-courses .enroll-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.enroll-courses .enroll-img img {
  overflow: hidden;
  transition: all ease 0.5s;
}
.enroll-courses .enroll-img .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
}
.enroll-courses .enroll-img .icon a {
  font-size: 35px;
  background-color: #ffffff;
  color: #e32845;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 100%;
  transition: all ease 0.5s;
}
.enroll-courses .enroll-img .icon a:hover {
  background-color: #e32845;
  color: #ffffff;
}
.enroll-courses .enroll-img .icon::before {
  position: absolute;
  content: "";
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.006);
  animation: ripple 4s linear infinite;
  z-index: -1;
}
.enroll-courses .enroll-img .icon::after {
  position: absolute;
  content: "";
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.004);
  animation: ripple 3s linear infinite;
  z-index: -1;
}
.enroll-courses .enroll-img:hover img {
  transform: scale(1.2);
  filter: blur(3px);
}
.enroll-courses .list {
  margin-bottom: 30px;
}
.enroll-courses .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.enroll-courses .list ul li {
  list-style-type: none;
  text-align: right;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e1e1;
}
.enroll-courses .list ul li span {
  position: absolute;
  left: 0;
  color: #111d5e;
  font-weight: 600;
}
.enroll-courses .list ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.enroll-courses .default-btn {
  display: block;
}

/*
Campus Life Style
======================================================*/
.single-campus-card {
  margin-bottom: 30px;
  transition: all ease 0.5s;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-campus-card .campus-content {
  padding: 30px;
  padding-bottom: 26px;
}
.single-campus-card .campus-content span {
  color: #e32845;
  margin-bottom: 15px;
  display: inline-block;
  font-weight: 500;
}
.single-campus-card .campus-content h3 {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 1.3;
  transition: all ease 0.5s;
}
.single-campus-card .campus-content h3:hover {
  color: #e32845;
}
.single-campus-card.style2 .campus-content {
  background-color: #ffffff;
}
.single-campus-card.style2 .campus-content span {
  position: relative;
  padding-left: 55px;
}
.single-campus-card.style2 .campus-content span::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 50px;
  background-color: #e32845;
  left: 0;
  bottom: 5px;
}
.single-campus-card.style2:hover {
  transform: translateY(0);
}
.single-campus-card.style-3 {
  position: relative;
}
.single-campus-card.style-3 .campus-content {
  background-color: #ffffff;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  transition: all ease 0.5s;
  transform: scaleY(0);
}
.single-campus-card.style-3:hover {
  transform: translateY(0);
}
.single-campus-card:hover {
  transform: translateY(-10px);
}

.campus-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.campus-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.campus-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.campus-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.campus-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.campus-slider.owl-theme .owl-dots {
  left: 0;
}
.campus-slider.owl-theme .owl-dots .owl-dot span {
  background-color: #e32845;
  margin: 10px 10px 0 10px;
  background-color: #bbbaba;
  height: 18px;
  width: 18px;
}
.campus-slider.owl-theme .owl-dots .owl-dot.active span {
  position: relative;
}
.campus-slider.owl-theme .owl-dots .owl-dot.active span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e32845;
  border-radius: 100%;
  top: 4px;
  left: 4px;
}

.campus-slider2.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.campus-slider2.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.campus-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.campus-slider2.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.campus-slider2.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}

.active.center .single-campus-card.style-3 .campus-content {
  transform: scaleY(1);
}

/*
Admission Style
======================================================*/
.admisssion-area.admission-bg {
  background-image: url(../images/page-banner/page-bnner-2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.admisssion-area.admission-bg::before {
  position: absolute;
  content: "";
  background: rgba(17, 29, 94, 0.75);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.admission-left-content {
  position: relative;
}
.admission-left-content h2 {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 20px;
}
.admission-left-content p {
  color: #ffffff;
  margin-bottom: 30px;
}

.admission-right-content {
  position: relative;
  text-align: right;
}
.admission-right-content p {
  padding-right: 100px;
  position: relative;
  color: #ffffff;
  top: 20px;
}
.admission-right-content .icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.admission-right-content .icon a {
  font-size: 35px;
  background-color: #ffffff;
  color: #e32845;
  text-align: center;
  height: 60px;
  width: 60px;
  display: inline-block;
  line-height: 60px;
  border-radius: 100%;
  transition: all ease 0.5s;
}
.admission-right-content .icon a:hover {
  background-color: #e32845;
  color: #ffffff;
}
.admission-right-content .icon::before {
  position: absolute;
  content: "";
  height: 70px;
  width: 70px;
  border-radius: 100%;
  left: -5px;
  top: -5px;
  background-color: rgba(255, 255, 255, 0.006);
  animation: ripple 4s linear infinite;
  z-index: -1;
}
.admission-right-content .icon::after {
  position: absolute;
  content: "";
  height: 80px;
  width: 80px;
  left: -10px;
  top: -10px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.004);
  animation: ripple 3s linear infinite;
  z-index: -1;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 0.6;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.admission-content .admission-image {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 10px;
}
.admission-content .admission-image img {
  overflow: hidden;
  transition: all ease 0.5s;
}
.admission-content .admission-image .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
}
.admission-content .admission-image .icon a {
  font-size: 35px;
  background-color: #e32845;
  color: #ffffff;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 100%;
  transition: all ease 0.5s;
}
.admission-content .admission-image .icon a:hover {
  background-color: #e32845;
  color: #ffffff;
}
.admission-content .admission-image .icon::before {
  position: absolute;
  content: "";
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(227, 40, 69, 0.006);
  animation: ripple 4s linear infinite;
  z-index: -1;
}
.admission-content .admission-image .icon::after {
  position: absolute;
  content: "";
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(227, 40, 69, 0.004);
  animation: ripple 3s linear infinite;
  z-index: -1;
}
.admission-content .admission-image:hover img {
  transform: scale(1.2);
  filter: blur(3px);
}
.admission-content .query p {
  margin-bottom: 30px;
}

.how-to-apply h2 {
  font-size: 48px;
  margin-bottom: 20px;
}
.how-to-apply p {
  margin-bottom: 30px;
}
.how-to-apply .apply-list {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}
.how-to-apply .apply-list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.how-to-apply .apply-list ul li {
  list-style-type: none;
  margin-bottom: 12px;
  position: relative;
  padding-left: 20px;
}
.how-to-apply .apply-list ul li i {
  position: absolute;
  color: #e32845;
  left: 0;
}

.submit {
  padding-bottom: 35px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}
.submit h3 {
  font-size: 30px;
  margin-bottom: 20px;
}
.submit p {
  margin-bottom: 25px;
}

.financial {
  margin-bottom: 30px;
}
.financial h3 {
  font-size: 30px;
  margin-bottom: 20px;
}
.financial p {
  margin-bottom: 25px;
}

.additional-information {
  background-color: #f4f6f9;
  padding: 30px;
  margin-bottom: 30px;
}
.additional-information h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.additional-information .list {
  text-align: center;
}
.additional-information .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.additional-information .list ul li {
  list-style-type: none;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #ffffff;
  transition: all ease 0.5s;
}
.additional-information .list ul li a {
  color: #555555;
  transition: all ease 0.5s;
}
.additional-information .list ul li:hover {
  background-color: #e32845;
}
.additional-information .list ul li:hover a {
  color: #ffffff;
}

/*
Program Style
======================================================*/
.single-program-card {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.single-program-card .program-content {
  padding: 30px;
}
.single-program-card .program-content h3 {
  font-size: 22px;
  margin-bottom: 17px;
}
.single-program-card .program-content h3 a {
  color: #111d5e;
  transition: all ease 0.5s;
}
.single-program-card .program-content h3 a:hover {
  color: #e32845;
}
.single-program-card .program-content p {
  margin-bottom: 17px;
}

/*
Health Care Style
======================================================*/
.single-health-care-card {
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-health-care-card .health-care-content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.single-health-care-card .health-care-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  transition: all ease 0.5s;
  line-height: 1.3;
}
.single-health-care-card .health-care-content p {
  margin-bottom: 15px;
}
.single-health-care-card.style1 {
  position: relative;
}
.single-health-care-card.style1 .health-care-content {
  background-color: #ffffff;
  max-width: 420px;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  bottom: -70px;
}
.single-health-care-card.style2 {
  position: relative;
  padding-top: 150px;
}
.single-health-care-card.style2 .health-care-content {
  background-color: #ffffff;
  max-width: 420px;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}
.single-health-care-card.style-3 {
  position: relative;
}
.single-health-care-card.style-3 .health-care-content {
  background-color: #ffffff;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
}
.single-health-care-card.style-3:hover {
  transform: translateX(0);
}
.single-health-care-card:hover {
  transform: translateX(-10px);
}
.single-health-care-card:hover .health-care-content h3 {
  color: #e32845;
}

.more-health-care.style2 {
  text-align: right !important;
  padding-right: 200px;
}

.health-care-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.health-care-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.health-care-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.health-care-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.health-care-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}

.health-details .top-content img {
  margin-bottom: 25px;
}
.health-details .top-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
}
.health-details .top-content p {
  margin-bottom: 25px;
}
.health-details .health-care h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.health-details .health-care p {
  margin-bottom: 20px;
}
.health-details .health-care-access {
  margin-bottom: 20px;
}
.health-details .health-care-access h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.health-details .health-care-access p {
  margin-bottom: 20px;
}

.madical-care-content .medical-card {
  margin-bottom: 30px;
}
.madical-care-content .medical-card .content {
  background-color: #e32845;
  text-align: center;
  padding: 20px 0 15px 0;
}
.madical-care-content .medical-card .content h4 {
  font-size: 22px;
  color: #ffffff;
}
.madical-care-content .medical-care {
  margin-bottom: 30px;
}
.madical-care-content .medical-care h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.madical-care-content .medical-care p {
  margin-bottom: 20px;
}
.madical-care-content .medical-care .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.madical-care-content .medical-care .list ul li {
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  padding-left: 20px;
}
.madical-care-content .medical-care .list ul li::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #e32845;
  left: 0;
  top: 6px;
}
.madical-care-content .medical-care .list ul li:last-child {
  margin-bottom: 10px;
}

.tranding {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.tranding h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.tranding .tranding-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d4d3d3;
}
.tranding .tranding-box .tranding-content {
  position: relative;
  padding-left: 105px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.tranding .tranding-box .tranding-content h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.tranding .tranding-box .tranding-content h4 a {
  color: #555555;
  transition: all ease 0.5s;
}
.tranding .tranding-box .tranding-content img {
  height: 80px;
  width: 90px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tranding .tranding-box:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.tranding .tranding-box:hover .tranding-content h4 a {
  color: #e32845;
}

/*
Management Style
======================================================*/
.single-events-card {
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-events-card .events-image {
  position: relative;
}
.single-events-card .events-image .date {
  background-color: #ffffff;
  position: absolute;
  bottom: -35px;
  left: 30px;
  padding: 15px 25px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.single-events-card .events-image .date span {
  font-size: 18px;
  color: #e32845;
  font-weight: 600;
  margin-bottom: 10px;
}
.single-events-card .events-content {
  padding: 30px;
  background-color: #ffffff;
}
.single-events-card .events-content .admin {
  text-align: right;
  margin-bottom: 20px;
}
.single-events-card .events-content .admin p a {
  color: #555555;
}
.single-events-card .events-content .admin p a i {
  color: #e32845;
  padding-right: 7px;
}
.single-events-card .events-content h3 {
  font-size: 24px;
  transition: all ease 0.5s;
  list-style: 1.3;
}
.single-events-card:hover {
  transform: translateX(-10px);
}
.single-events-card:hover .events-content h3 {
  color: #e32845;
}
.single-events-card.style2 .events-content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-events-card.style2 .events-content h3 {
  margin-bottom: 15px;
}
.single-events-card.style2 .events-content .admin-and-date ul {
  padding-left: 0;
  margin-bottom: 0;
}
.single-events-card.style2 .events-content .admin-and-date ul li {
  list-style-type: none;
}
.single-events-card.style2 .events-content .admin-and-date ul li a {
  color: #555555;
}
.single-events-card.style2 .events-content .admin-and-date ul li a i {
  color: #e32845;
  margin-right: 7px;
}
.single-events-card.style2:hover {
  transform: translateX(0);
}
.single-events-card.style-3 .events-content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-events-card.style-3 .events-content .date-and-read-more ul {
  padding-left: 0;
  margin-bottom: 0;
}
.single-events-card.style-3 .events-content .date-and-read-more ul li {
  list-style-type: none;
}
.single-events-card.style-3 .events-content .date-and-read-more ul li p i {
  color: #e32845;
  position: relative;
  top: 2px;
  margin-right: 7px;
}
.single-events-card.style-4 .events-content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.events-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.events-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.events-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.events-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.events-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.events-slider.owl-theme .owl-dots {
  left: 0;
}
.events-slider.owl-theme .owl-dots .owl-dot span {
  background-color: #e32845;
  margin: 10px 10px 0 10px;
  background-color: #dbdada;
  height: 18px;
  width: 18px;
}
.events-slider.owl-theme .owl-dots .owl-dot.active span {
  position: relative;
}
.events-slider.owl-theme .owl-dots .owl-dot.active span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e32845;
  border-radius: 100%;
  top: 4px;
  left: 4px;
}

.events-details-left-content .events-image {
  margin-bottom: 30px;
}

.meetings {
  padding-bottom: 15px;
  border-bottom: 1px solid #f4f6f9;
  margin-bottom: 30px;
}
.meetings h2 {
  font-size: 36px;
  margin-bottom: 20px;
}
.meetings p {
  margin-bottom: 20px;
}

.about-lecturer h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.about-lecturer img {
  margin-bottom: 30px;
}
.about-lecturer p {
  margin-bottom: 30px;
}
.about-lecturer .address {
  margin-bottom: 15px;
}
.about-lecturer .address h4 {
  font-size: 20px;
  margin-bottom: 20px;
}
.about-lecturer .address .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.about-lecturer .address .list ul li {
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  padding-left: 20px;
}
.about-lecturer .address .list ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #e32845;
  left: 0;
  top: 7px;
}
.about-lecturer .address .list ul li a {
  color: #555555;
}
.about-lecturer .social-content {
  margin-bottom: 30px;
}
.about-lecturer .social-content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.about-lecturer .social-content ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.about-lecturer .social-content ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  background-color: #f4f6f9;
  color: #e32845;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  transition: all ease 0.5s;
}
.about-lecturer .social-content ul li a:hover {
  background-color: #e32845;
  color: #ffffff;
  transform: translateY(-5px);
}

.next-workshop {
  margin-bottom: 30px;
}
.next-workshop h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.next-workshop .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.next-workshop .list ul li {
  list-style-type: none;
  margin-bottom: 10px;
  position: relative;
  padding-left: 120px;
}
.next-workshop .list ul li span {
  position: absolute;
  left: 0;
}
.next-workshop .list ul li:last-child {
  margin-bottom: 0;
}

.brought {
  margin-bottom: 30px;
}
.brought h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.brought p {
  margin-bottom: 20px;
}
.brought .default-btn {
  margin-top: 10px;
}

.counter-area ul {
  padding-left: 0;
  margin-bottom: 0;
}
.counter-area ul li {
  list-style-type: none;
}

/*
Podcasts Care Style
======================================================*/
.single-podcasts-card {
  margin-bottom: 30px;
}
.single-podcasts-card .podcast-content span {
  color: #e32845;
  display: inline-block;
  margin-bottom: 10px;
}
.single-podcasts-card .podcast-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 1.3;
}
.single-podcasts-card .podcast-content p {
  margin-bottom: 20px;
}
.single-podcasts-card .podcast-content .play-icon {
  transition: all ease 0.5s;
  padding-top: 5px;
  max-width: 170px;
}
.single-podcasts-card .podcast-content .play-icon i {
  color: #ffffff;
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 100%;
  font-size: 24px;
  background-color: #e32845;
  display: inline-block;
  text-align: center;
  transition: all ease 0.5s;
}
.single-podcasts-card .podcast-content .play-icon span {
  position: relative;
  display: inline-block;
  bottom: 8px;
  padding-left: 7px;
  transition: all ease 0.5s;
}
.single-podcasts-card .podcast-content .play-icon:hover {
  background-color: #e32845;
  padding-top: 5px;
}
.single-podcasts-card .podcast-content .play-icon:hover span {
  color: #ffffff;
}
.single-podcasts-card.style2 .podcasts-image {
  position: relative;
}
.single-podcasts-card.style2 .podcasts-image .play-btn {
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 100%;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ffffff;
  color: #ffffff;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  transition: all ease 0.5s;
}
.single-podcasts-card.style2 .podcasts-image .play-btn:hover {
  background-color: #ffffff;
  color: #e32845;
  border-color: #ffffff;
}
.single-podcasts-card.style2 .podcast-content {
  background-color: #ffffff;
  padding: 30px;
}
.single-podcasts-card.style2 .podcast-content ul {
  padding-left: 0;
  margin-bottom: 15px;
}
.single-podcasts-card.style2 .podcast-content ul li {
  list-style-type: none;
  color: #e32845;
}
.single-podcasts-card.style2 .podcast-content ul li.date {
  position: relative;
  padding-left: 35px;
}
.single-podcasts-card.style2 .podcast-content ul li.date::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 30px;
  background-color: #e32845;
  top: 12px;
  left: 0;
}
.single-podcasts-card.style2 .podcast-content h3 {
  margin-bottom: 0;
}
.single-podcasts-card.style3 .podcasts-image {
  position: relative;
}
.single-podcasts-card.style3 .podcasts-image .play-btn {
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 100%;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #e32845;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
  transition: all ease 0.5s;
}
.single-podcasts-card.style3 .podcasts-image .play-btn:hover {
  background-color: #e32845;
  color: #ffffff;
}
.single-podcasts-card.style3 .podcasts-image .episodes p {
  background-color: #ffffff;
  color: #e32845;
  padding: 10px 15px;
  position: absolute;
  top: 30px;
  left: 30px;
  border-radius: 7px;
}
.single-podcasts-card.style3 .podcast-content {
  padding: 30px 30px 0 30px;
}
.single-podcasts-card.style3 .podcast-content .date p {
  color: #e32845;
  font-weight: 500;
  position: relative;
  padding-left: 15px;
}
.single-podcasts-card.style3 .podcast-content .date p::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #e32845;
  left: 0;
  top: 7px;
}

.categories {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.categories h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.categories ul {
  padding-left: 0;
  margin-bottom: 0;
}
.categories ul li {
  position: relative;
  list-style-type: none;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.categories ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.categories ul li a {
  color: #555555;
  transition: all ease 0.5s;
}
.categories ul li i {
  color: #e32845;
  padding-right: 10px;
}
.categories ul li:hover a {
  color: #e32845;
}
.categories.style-2 {
  background-color: #ffffff;
}

.subscribe-area {
  background-image: url(../images/subscribe-img.jpg);
  background-size: cover;
  padding: 30px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.subscribe-area::before {
  position: absolute;
  content: "";
  background: rgba(17, 29, 94, 0.7);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.subscribe-area .top-content {
  position: relative;
}
.subscribe-area .top-content i {
  font-size: 40px;
  color: #ffffff;
}
.subscribe-area .top-content h3 {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 15px;
}
.subscribe-area .top-content p {
  margin-bottom: 20px;
  color: #ffffff;
}
.subscribe-area .newsletter-form {
  position: relative;
}
.subscribe-area .newsletter-form .form-control {
  margin-bottom: 30px;
}
.subscribe-area .newsletter-form .default-btn {
  width: 100%;
}
.subscribe-area .newsletter-form .validation-danger {
  color: #e32845;
  margin-top: 5px;
}

/*
Stories Style
======================================================*/
.single-stories-card {
  margin-bottom: 30px;
}
.single-stories-card .stories-content {
  padding: 30px 30px 20px 30px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-stories-card .stories-content h3 {
  font-size: 22px;
}
.single-stories-card iframe {
  height: 234px;
  width: 100%;
}

/*
Latest News Style
======================================================*/
.single-news-card {
  margin-bottom: 30px;
  transition: all ease 0.5s;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-news-card .news-img {
  overflow: hidden;
}
.single-news-card .news-img img {
  transition: all ease 0.5s;
}
.single-news-card .news-content {
  padding: 30px;
}
.single-news-card .news-content .list {
  margin-bottom: 15px;
}
.single-news-card .news-content .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.single-news-card .news-content .list ul li {
  display: inline-block;
  margin-right: 15px;
}
.single-news-card .news-content .list ul li i {
  padding-right: 7px;
  color: #e32845;
}
.single-news-card .news-content .list ul li a {
  color: #555555;
}
.single-news-card .news-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
  transition: all ease 0.5s;
  line-height: 1.3;
}
.single-news-card:hover .news-img img {
  transform: scale(1.1);
}
.single-news-card:hover .news-content h3 {
  color: #e32845;
}
.single-news-card.style2 .news-content {
  background-color: #ffffff;
}
.single-news-card.style3 {
  background-color: #ffffff;
}
.single-news-card.style3 .news-content {
  background-color: #ffffff;
}

.news-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  bottom: 30px;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #dfdbdb;
  height: 40px;
  width: 40px;
  line-height: 43px;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 24px;
}
.news-slider.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -2px;
  left: -1px;
}
.news-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -80px;
  left: auto;
}
.news-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
  position: relative;
  right: -1px;
  left: auto;
  top: -2px;
}
.news-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e32845;
  color: #ffffff;
  border: 1px solid #e32845;
}
.news-slider.owl-theme .owl-dots {
  left: 0;
}
.news-slider.owl-theme .owl-dots .owl-dot span {
  background-color: #e32845;
  margin: 0 15px;
  background-color: #ded6d6;
  height: 18px;
  width: 18px;
}
.news-slider.owl-theme .owl-dots .owl-dot.active span {
  position: relative;
}
.news-slider.owl-theme .owl-dots .owl-dot.active span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e32845;
  border-radius: 100%;
  top: 4px;
  left: 4px;
}

.latest-news-simple-card {
  background-image: url(../images/news/news-5.jpg);
  background-size: cover;
  background-position: center center;
  height: 450px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.latest-news-simple-card::before {
  position: absolute;
  content: "";
  background: rgba(17, 29, 94, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.latest-news-simple-card .news-content {
  padding: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.latest-news-simple-card .news-content .list {
  margin-bottom: 15px;
}
.latest-news-simple-card .news-content .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.latest-news-simple-card .news-content .list ul li {
  display: inline-block;
  margin-right: 15px;
  color: #ffffff;
}
.latest-news-simple-card .news-content .list ul li i {
  padding-right: 7px;
  color: #e32845;
}
.latest-news-simple-card .news-content .list ul li a {
  color: #ffffff;
}
.latest-news-simple-card .news-content h3 {
  font-size: 36px;
  margin-bottom: 15px;
  color: #ffffff;
}

.latest-news-card-area h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.category-list {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.category-list h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.category-list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.category-list ul li {
  list-style-type: none;
  position: relative;
  margin-bottom: 10px;
}
.category-list ul li a {
  color: #555555;
  transition: all ease 0.5s;
}
.category-list ul li i {
  position: absolute;
  right: 0;
  font-size: 22px;
}
.category-list ul li:last-child {
  margin-bottom: 0;
}
.category-list ul li:hover a {
  color: #e32845;
}

.related-post-area {
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.related-post-area h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.related-post-area .related-post-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d4d3d3;
}
.related-post-area .related-post-box .related-post-content {
  position: relative;
  padding-left: 105px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.related-post-area .related-post-box .related-post-content h4 {
  font-size: 16px;
  margin-bottom: 10px;
}
.related-post-area .related-post-box .related-post-content h4 a {
  color: #555555;
  transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.related-post-area .related-post-box .related-post-content h4:hover a {
  color: #e32845;
}
.related-post-area .related-post-box .related-post-content img {
  height: 80px;
  width: 90px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.related-post-area .related-post-box .related-post-content p i {
  color: #e32845;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.related-post-area .related-post-box:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.related-post-area .related-post-box:hover .tranding-content h4 a {
  color: #e32845;
}

.news-details .news-simple-card img {
  margin-bottom: 20px;
}
.news-details .news-simple-card .list {
  margin-bottom: 15px;
}
.news-details .news-simple-card .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.news-details .news-simple-card .list ul li {
  display: inline-block;
  margin-right: 15px;
}
.news-details .news-simple-card .list ul li i {
  padding-right: 7px;
  color: #e32845;
}
.news-details .news-simple-card .list ul li a {
  color: #555555;
}
.news-details .news-simple-card h2 {
  font-size: 36px;
  margin-bottom: 20px;
}
.news-details .news-simple-card p {
  margin-bottom: 20px;
}
.news-details .theory .theory-box {
  padding-left: 30px;
  margin-bottom: 30px;
}
.news-details .theory .theory-box h4 {
  font-size: 18px;
  position: relative;
}
.news-details .theory .theory-box h4::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #e32845;
  left: -30px;
  top: 5px;
}
.news-details .blog-img-and-content {
  margin-bottom: 30px;
}
.news-details .blog-img-and-content img {
  margin-bottom: 30px;
}

.tags-and-share {
  padding-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.tags-and-share .tags ul {
  padding-left: 0;
  margin-bottom: 0;
}
.tags-and-share .tags ul li {
  margin-right: 10px;
  display: inline-block;
}
.tags-and-share .tags ul li span {
  font-size: 18px;
  font-weight: 500;
  color: #111d5e;
  margin-right: 5px;
}
.tags-and-share .tags ul li a {
  color: #555555;
  transition: all ease 0.5s;
  position: relative;
}
.tags-and-share .tags ul li a:hover {
  color: #e32845;
}
.tags-and-share .tags ul li a::before {
  position: absolute;
  content: ",";
  right: -4px;
}
.tags-and-share .tags ul li:last-child a::before {
  display: none;
}
.tags-and-share .share {
  text-align: right;
}
.tags-and-share .share ul {
  padding-left: 0;
  margin-bottom: 0;
}
.tags-and-share .share ul li {
  display: inline-block;
}
.tags-and-share .share ul li span {
  font-size: 18px;
  font-weight: 500;
  color: #111d5e;
  margin-right: 15px;
}
.tags-and-share .share ul li a {
  height: 35px;
  width: 35px;
  line-height: 38px;
  border-radius: 100%;
  background-color: #f4f6f9;
  color: #e32845;
  display: inline-block;
  text-align: center;
  transition: all ease 0.5s;
}
.tags-and-share .share ul li a:hover {
  background-color: #e32845;
  color: #ffffff;
}

/*
Footer Style
======================================================*/
.footer-area {
  background-color: #111d5e;
  position: relative;
  z-index: 1;
}
.footer-area .shape {
  position: absolute;
  top: 50px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  right: 0;
  z-index: -1;
}

.footer-logo-area {
  margin-bottom: 30px;
}
.footer-logo-area img {
  margin-bottom: 20px;
}
.footer-logo-area p {
  margin-bottom: 25px;
  color: #ffffff;
}
.footer-logo-area .contact-list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.footer-logo-area .contact-list ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
.footer-logo-area .contact-list ul li:last-child {
  margin-bottom: 0;
}
.footer-logo-area .contact-list ul li a {
  color: #ffffff;
}

.footer-widjet {
  margin-bottom: 30px;
}
.footer-widjet h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 30px;
}
.footer-widjet .list ul {
  padding-left: 0;
  margin-bottom: 0;
}
.footer-widjet .list ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
.footer-widjet .list ul li:last-child {
  margin-bottom: 0;
}
.footer-widjet .list ul li a {
  color: #ffffff;
  transition: all ease 0.5s;
}
.footer-widjet .list ul li a:hover {
  color: #e32845;
}

/*
CopyRight Style
======================================================*/
.copyright-area {
  background-color: #1f2c75;
}

.copyright {
  padding: 30px 0;
}
.copyright .copy {
  text-align: right;
}
.copyright .copy p {
  color: #cacaca;
}
.copyright .copy p a {
  color: #e32845;
}
.copyright .social-content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.copyright .social-content ul li {
  display: inline-block;
  margin-right: 10px;
}
.copyright .social-content ul li:last-child {
  margin-right: 0;
}
.copyright .social-content ul li span {
  color: #ffffff;
}
.copyright .social-content ul li a {
  color: #cacaca;
  transition: all ease 0.5s;
}
.copyright .social-content ul li a:hover {
  color: #e32845;
}

/*
Page Banner Style
======================================================*/
.page-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
}
.page-banner-area::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(17, 29, 94, 0.8);
}
.page-banner-area.bg-1 {
  background-image: url(../images/page-banner/page-bnner-1.jpg);
}
.page-banner-area.bg-2 {
  background-image: url(../images/page-banner/page-bnner-2.jpg);
}
.page-banner-area .container-fluid {
  max-width: 1520px;
  margin: auto;
}

.page-banner-content {
  position: relative;
}
.page-banner-content h1 {
  font-size: 50px;
  margin-bottom: 10px;
  color: #ffffff;
}
.page-banner-content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.page-banner-content ul li {
  display: inline-block;
  position: relative;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.page-banner-content ul li a {
  text-decoration: none;
  color: #ffffff;
}
.page-banner-content ul li::before {
  position: absolute;
  content: "/";
  right: 2px;
  top: 0;
}
.page-banner-content ul li:last-child {
  color: #e32845;
  padding-right: 0;
}
.page-banner-content ul li:last-child::before {
  display: none;
}

/*
Student life Style
======================================================*/
.student-life-card {
  margin-bottom: 30px;
  position: relative;
}
.student-life-card .tags {
  background-color: #e32845;
  padding: 10px 15px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.student-life-card .tags p {
  color: #ffffff;
}

/*
Alumni life Style
======================================================*/
.alumni-left-content h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.single-alumni-events-card {
  padding: 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-alumni-events-card .alumni-img {
  position: relative;
}
.single-alumni-events-card .alumni-img img {
  width: 100%;
}
.single-alumni-events-card .alumni-img .date {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e32845;
  padding: 8px 12px;
}
.single-alumni-events-card .alumni-img .date p {
  color: #ffffff;
  font-size: 13px;
}
.single-alumni-events-card .alumni-content .time {
  margin-bottom: 15px;
}
.single-alumni-events-card .alumni-content .time p i {
  color: #e32845;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.single-alumni-events-card .alumni-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
.single-alumni-events-card .alumni-content .location p i {
  color: #e32845;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.single-alumni-events-card:hover {
  transform: translateY(-10px);
}

.alumni-right-content h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.single-alumoni-updates-card {
  padding: 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-alumoni-updates-card .update-image img {
  width: 100%;
}
.single-alumoni-updates-card .updates-content .date {
  margin-bottom: 10px;
}
.single-alumoni-updates-card .updates-content h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.single-alumoni-updates-card:hover {
  transform: translateY(-10px);
}

.interview-content {
  background-image: url(../images/alumni/alumni-7.jpg);
  background-size: cover;
  background-position: center center;
}
.interview-content .interview {
  max-width: 465px;
  margin-right: 20px;
  margin-left: auto;
}
.interview-content .interview span {
  margin-bottom: 10px;
  display: inline-block;
}
.interview-content .interview h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
.interview-content .interview p {
  margin-bottom: 30px;
}

/*
Contact Us Style
======================================================*/
.contacts-form {
  padding: 40px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.contacts-form h3 {
  font-size: 30px;
  margin-bottom: 20px;
}
.contacts-form .form-group {
  margin-bottom: 25px;
}
.contacts-form .form-group label {
  margin-bottom: 7px;
}
.contacts-form .form-group .form-control {
  background-color: #f4f6f9;
  border: 1px solid transparent;
}
.contacts-form .form-group .form-control:focus {
  border: 1px solid #e32845;
}
.contacts-form .list-unstyled {
  color: #e32845;
  margin-top: 7px;
}
.contacts-form .text-danger {
  color: #e32845;
  font-size: 16px;
  margin-top: 7px;
}
.contacts-form .form-check .form-check-input {
  border-radius: 0;
}
.contacts-form .form-check .form-check-input:checked {
  background-color: #e32845;
  border-color: #e32845;
}
.contacts-form .form-check .form-check-input:focus {
  box-shadow: none;
}
.contacts-form .default-btn {
  width: 100%;
}

.contact-and-address h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
.contact-and-address p {
  margin-bottom: 20px;
}
.contact-and-address .contact-and-address-content {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.contact-and-address .contact-and-address-content .contact-card {
  text-align: center;
  padding: 30px;
  background-color: #f4f6f9;
  margin-bottom: 30px;
}
.contact-and-address .contact-and-address-content .contact-card .icon {
  font-size: 35px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  border: 1px solid #e32845;
  color: #e32845;
  text-align: center;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 15px;
}
.contact-and-address .contact-and-address-content .contact-card h4 {
  font-size: 22px;
  margin-bottom: 15px;
}
.contact-and-address .contact-and-address-content .contact-card p {
  margin-bottom: 10px;
}
.contact-and-address .contact-and-address-content .contact-card p a {
  color: #555555;
  transition: all ease 0.5s;
}
.contact-and-address .contact-and-address-content .contact-card p a:hover {
  color: #e32845;
}
.contact-and-address .social-media {
  margin-bottom: 30px;
}
.contact-and-address .social-media h3 {
  font-size: 24px;
  margin-bottom: 15px;
}
.contact-and-address .social-media p {
  margin-bottom: 25px;
}
.contact-and-address .social-media ul {
  padding-left: 0;
  margin-bottom: 0;
}
.contact-and-address .social-media ul li {
  margin-right: 10px;
  list-style-type: none;
  display: inline-block;
}
.contact-and-address .social-media ul li a {
  height: 35px;
  width: 35px;
  line-height: 37px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  background-color: #eeeeee;
  color: #e32845;
  transition: all ease 0.5s;
}
.contact-and-address .social-media ul li a:hover {
  background-color: #e32845;
  color: #ffffff;
  transform: translateY(-5px);
}

/*
Go To Top Style
======================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 87%;
  right: -10%;
  background-color: #e32845;
  z-index: 4;
  width: 50px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  transition: 0.5s;
  font-size: 26px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: -1;
  background-color: #e32845;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover {
  color: #ffffff;
  background: #111d5e;
}
.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i {
  color: #ffffff;
}
.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top:focus {
  color: #ffffff;
}
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top.active {
  transform: translateY(-95%);
  opacity: 1;
  visibility: visible;
  right: 3%;
  top: 93%;
}

/*
Faq Area Style
======================================================*/
.faq-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
}
.faq-title p {
  margin-bottom: 30px;
}

.accordion .accordion-item {
  margin-bottom: 20px;
  border: 1px solid rgba(227, 40, 69, 0.4);
  border-radius: 10px;
}
.accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-button {
  border: none;
  background-color: transparent;
  color: #111d5e;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
  outline: none;
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: unset;
}
.accordion .accordion-item .accordion-button::after {
  background-image: unset;
  content: "";
  font-size: 20px;
  color: #111d5e;
  text-align: center;
  content: "\ea13";
  font-family: "remixicon" !important;
  position: relative;
  top: -2px;
}
.accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: unset;
  text-align: center;
  content: "\f1af";
  position: relative;
  top: -2px;
}
.accordion .accordion-item .accordion-body {
  padding-top: 0;
  font-size: 15px;
}

/*
Login Area Style
======================================================*/
.login {
  max-width: 860px;
  margin: auto;
  padding: 50px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.login h3 {
  font-size: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  position: relative;
}
.login h3::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 70px;
  background-color: #e32845;
  top: 43px;
}
.login .form-group {
  margin-bottom: 20px;
}
.login .form-check {
  margin-bottom: 20px;
}
.login .form-check .form-check-input:checked {
  background-color: #e32845;
}
.login .form-check .form-check-input:focus {
  box-shadow: none;
}
.login .default-btn {
  padding: 15px 40px;
  margin-bottom: 15px;
}
.login a {
  display: inherit;
  color: #555555;
  font-weight: 500;
}

/*
Register Area Style
======================================================*/
.register {
  max-width: 860px;
  margin: auto;
  padding: 50px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.register h3 {
  font-size: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  position: relative;
}
.register h3::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 70px;
  background-color: #e32845;
  top: 43px;
}
.register .form-group {
  margin-bottom: 20px;
}
.register .default-btn {
  padding: 15px 40px;
  margin-top: 10px;
}

/*
Password Area Style
======================================================*/
.password {
  max-width: 650px;
  margin: auto;
  padding: 50px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.password h3 {
  font-size: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  position: relative;
}
.password h3::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 70px;
  background-color: #e32845;
  top: 43px;
}
.password .form-group {
  margin-bottom: 20px;
}
.password .default-btn {
  width: 100%;
}

/*
Privacy Policy Style
======================================================*/
.privacy-content {
  margin-bottom: 10px;
}
.privacy-content h2 {
  font-size: 36px;
  margin-bottom: 10px;
}
.privacy-content h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
.privacy-content p {
  margin-bottom: 25px;
}
.privacy-content .list ul {
  margin-bottom: 20px;
  padding-left: 0;
}
.privacy-content .list ul li {
  font-weight: 600;
  color: #111d5e;
  position: relative;
  padding-left: 20px;
  list-style-type: none;
  padding-bottom: 15px;
}
.privacy-content .list ul li i {
  position: absolute;
  left: 0;
  color: #e32845;
}

/*
Terms & Condition Style
======================================================*/
.condition-content {
  margin-bottom: 10px;
}
.condition-content h2 {
  font-size: 36px;
  margin-bottom: 30px;
}
.condition-content h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
.condition-content p {
  margin-bottom: 25px;
}
.condition-content .list ul {
  margin-bottom: 20px;
  padding-left: 0;
}
.condition-content .list ul li {
  font-weight: 600;
  color: #111d5e;
  position: relative;
  padding-left: 20px;
  list-style-type: none;
  padding-bottom: 15px;
}
.condition-content .list ul li i {
  position: absolute;
  left: 0;
  color: #e32845;
}

/*
Error Area Style
======================================================*/
.error-area {
  text-align: center;
}
.error-area .top-content {
  margin-bottom: 10px;
}
.error-area .top-content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.error-area .top-content ul li {
  font-size: 200px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
  margin-right: 30px;
  color: #e32845;
  font-family: "Source Serif Pro", serif;
}
.error-area .top-content ul li:first-child {
  color: #111d5e;
}
.error-area .top-content ul li:last-child {
  color: #555555;
}
.error-area h2 {
  font-size: 36px;
  margin-bottom: 20px;
}
.error-area p {
  margin-bottom: 30px;
}

/*
Coming Soon Style
======================================================*/
.coming-soon-area {
  height: 100vh;
  vertical-align: middle;
  position: relative;
}
.coming-soon-area::before {
  position: absolute;
  content: "";
  background: #0e0e0e;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.coming-soon-area .coming-soon {
  text-align: center;
  max-width: 720px;
  margin: auto;
  background-color: #000000;
  padding: 40px;
  border-radius: 40px 10px 40px 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
}
.coming-soon-area .coming-soon img {
  margin-bottom: 20px;
}
.coming-soon-area .coming-soon h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff;
}
.coming-soon-area .coming-soon .list ul {
  margin-bottom: 0;
  padding-left: 0;
}
.coming-soon-area .coming-soon .list ul li {
  margin: 0 20px;
  font-size: 36px;
  font-weight: 700;
  height: 100px;
  width: 100px;
  border: 6px double #e32845;
  border-radius: 50px 0px 0px 0px;
  margin-bottom: 30px;
  color: #ffffff;
}
.coming-soon-area .coming-soon .list ul li span {
  font-size: 15px;
  font-weight: 500;
  color: #e32845;
}
.coming-soon-area .coming-soon .newsletter-form {
  position: relative;
}
.coming-soon-area .coming-soon .newsletter-form .form-control {
  border-radius: 30px;
  height: 53px;
  background-color: #0e0e0e;
  color: #ffffff;
}
.coming-soon-area .coming-soon .newsletter-form .form-control::-moz-placeholder {
  color: #ffffff;
}
.coming-soon-area .coming-soon .newsletter-form .form-control::placeholder {
  color: #ffffff;
}
.coming-soon-area .coming-soon .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.coming-soon-area .coming-soon .newsletter-form .validation-danger {
  color: #e60808;
  padding-top: 7px;
}/*# sourceMappingURL=style.css.map */