/*================================================
Default CSS
=================================================*/
.switch-box {
  position: fixed;
  z-index: 1;
  left: 3%;
  bottom: 63px;
}
.switch-box .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch-box .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4d4949;
  transition: 0.4s;
}
.switch-box .slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 2.5px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: 0.4s;
  box-shadow: 0 0px 15px rgba(32, 32, 32, 0.2392156863);
  background: url(../images/night.png);
  background-repeat: no-repeat;
  background-position: center;
}
.switch-box input:checked + .slider {
  background-color: #cbc1c1;
}
.switch-box input:focus + .slider {
  background-color: #4d4949;
}
.switch-box input:checked + .slider:before {
  transform: translateX(24px);
  background: url(../images/sunny.png);
  background-repeat: no-repeat;
  background-position: center;
}
.switch-box .slider.round {
  border-radius: 50px;
}
.switch-box .slider.round:before {
  border-radius: 50%;
}

.theme-dark body {
  background-color: #000000;
  color: #f1f1f1;
}
.theme-dark p {
  color: #f1f1f1;
}
.theme-dark p a {
  color: #f1f1f1 !important;
}
.theme-dark p a:hover {
  color: #e32845 !important;
}
.theme-dark .bg-f4f6f9 {
  background-color: #0e0e0e;
}
.theme-dark .h1, .theme-dark .h2, .theme-dark .h3, .theme-dark .h4, .theme-dark .h5, .theme-dark .h6, .theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
  color: #ffffff !important;
}
.theme-dark .h1 a, .theme-dark .h2 a, .theme-dark .h3 a, .theme-dark .h4 a, .theme-dark .h5 a, .theme-dark .h6 a, .theme-dark h1 a, .theme-dark h2 a, .theme-dark h3 a, .theme-dark h4 a, .theme-dark h5 a, .theme-dark h6 a {
  color: #ffffff !important;
}
.theme-dark .h1 a:hover, .theme-dark .h2 a:hover, .theme-dark .h3 a:hover, .theme-dark .h4 a:hover, .theme-dark .h5 a:hover, .theme-dark .h6 a:hover, .theme-dark h1 a:hover, .theme-dark h2 a:hover, .theme-dark h3 a:hover, .theme-dark h4 a:hover, .theme-dark h5 a:hover, .theme-dark h6 a:hover {
  color: #e32845 !important;
}
.theme-dark .nav-bg-1 {
  background-color: #000000;
}
.theme-dark .nav-bg-2 {
  background-color: #000000;
}
.theme-dark .navbar-area .navbar-brand .white-logo {
  display: block;
}
.theme-dark .navbar-area .navbar-brand .main-logo {
  display: none;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item:hover a, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item:focus a, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background: #0e0e0e;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
  border-bottom: 1px dashed #000000;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
  border-bottom: 1px dashed #000000;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #e32845;
}
.theme-dark .desktop-nav .navbar .others-options ul li .call-now a {
  color: #ffffff;
}
.theme-dark .desktop-nav .navbar .others-options ul li .default-btn {
  color: #ffffff;
}
.theme-dark .others-option-for-responsive .others-options {
  background-color: #0e0e0e;
}
.theme-dark .others-option-for-responsive .others-options ul li .call-now a {
  color: #ffffff;
}
.theme-dark .preloader-area {
  background-color: #000000;
}
.theme-dark .default-btn {
  color: #ffffff;
}
.theme-dark .read-more-btn {
  color: #ffffff;
}
.theme-dark .top-header-area {
  background-color: #0e0e0e;
}
.theme-dark .banner-area {
  background-color: #0e0e0e;
}
.theme-dark .slider-content.style2 {
  background-color: #000000;
}
.theme-dark .slider-content.style2 p {
  color: #ffffff;
}
.theme-dark .single-academics-card {
  background-color: #0e0e0e;
}
.theme-dark .estemate-form {
  background-color: #0e0e0e;
}
.theme-dark .estemate-form .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
  border-color: #383737;
}
.theme-dark .single-courses-card .courses-content {
  background-color: #000000;
}
.theme-dark .single-courses-card.style2 .courses-content {
  background-color: #0e0e0e;
}
.theme-dark .single-courses-card.style4 .courses-content {
  background-color: #0e0e0e;
}
.theme-dark .courses-details .courses-card .list ul li span {
  color: #ffffff;
}
.theme-dark .description .nav-tabs {
  background-color: #0e0e0e;
}
.theme-dark .description .nav-tabs .nav-link {
  color: #ffffff;
}
.theme-dark .curriculum .curriculum-list {
  background-color: #0e0e0e;
}
.theme-dark .single-reviews-card {
  background-color: #0e0e0e;
}
.theme-dark .review-form .form-group .form-control {
  background-color: #0e0e0e;
  color: #ffffff;
}
.theme-dark .courses-slider.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
  color: #ffffff;
}
.theme-dark .enroll-courses {
  background-color: #0e0e0e;
}
.theme-dark .enroll-courses .list ul li span {
  color: #ffffff;
}
.theme-dark .single-campus-card .campus-content {
  background-color: #0e0e0e;
}
.theme-dark .single-campus-card.style2 .campus-content {
  background-color: #000000;
}
.theme-dark .campus-content.style-2 {
  background-color: #000000;
}
.theme-dark .campus-slider.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
  color: #ffffff;
}
.theme-dark .campus-slider2.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
  color: #ffffff;
}
.theme-dark .counter-card h1 .target.heading-color {
  color: #ffffff;
}
.theme-dark .single-health-care-card .health-care-content {
  background-color: #0e0e0e;
}
.theme-dark .single-health-care-card.style1 .health-care-content {
  background-color: #000000;
}
.theme-dark .single-health-care-card.style2 .health-care-content {
  background-color: #000000;
}
.theme-dark .health-care-slider.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
  color: #ffffff;
}
.theme-dark .tranding {
  background-color: #0e0e0e;
}
.theme-dark .single-events-card .events-content {
  background-color: #000000;
}
.theme-dark .single-events-card.style2 .events-content {
  background-color: #0e0e0e;
}
.theme-dark .single-events-card.style-3 .events-content {
  background-color: #0e0e0e;
}
.theme-dark .single-events-card.style-4 .events-content {
  background-color: #0e0e0e;
}
.theme-dark .single-events-card .events-image .date {
  background-color: #1c1b1b;
}
.theme-dark .events-slider.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
}
.theme-dark .events-details-left-content .about-lecturer .address .list ul li a {
  color: #ffffff;
}
.theme-dark .single-podcasts-card.style2 .podcast-content {
  background-color: #000000;
}
.theme-dark .single-podcasts-card.style3 .podcast-content {
  background-color: #0e0e0e;
}
.theme-dark .single-program-card {
  background-color: #000000;
}
.theme-dark .additional-information {
  background-color: #0e0e0e;
}
.theme-dark .additional-information .list ul li {
  background-color: #000000;
}
.theme-dark .additional-information .list ul li a {
  color: #ffffff;
}
.theme-dark .additional-information .list ul li:hover {
  background-color: #e32845;
}
.theme-dark .single-alumni-events-card {
  background-color: #0e0e0e;
}
.theme-dark .single-alumoni-updates-card {
  background-color: #0e0e0e;
}
.theme-dark .interview-content .interview span {
  color: #000000;
}
.theme-dark .interview-content .interview h2 {
  color: #000000 !important;
}
.theme-dark .interview-content .interview p {
  color: #000000;
}
.theme-dark .page-banner-area::before {
  background: rgba(21, 21, 24, 0.8);
}
.theme-dark .categories {
  background-color: #191818;
}
.theme-dark .categories ul li a {
  color: #ffffff;
}
.theme-dark .subscribe-area::before {
  background: rgba(14, 14, 14, 0.7);
}
.theme-dark .serch-content {
  background-color: #0e0e0e;
}
.theme-dark .serch-content .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .serch-content .form-group .src-btn {
  color: #ffffff;
}
.theme-dark .category-content {
  background-color: #0e0e0e;
}
.theme-dark .category-content .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .academics-list {
  background-color: #0e0e0e;
}
.theme-dark .academics-list ul li a {
  color: #ffffff;
}
.theme-dark .related-download {
  background-color: #0e0e0e;
}
.theme-dark .related-download ul li {
  background-color: #000000;
}
.theme-dark .related-download ul li a {
  color: #ffffff;
}
.theme-dark .category-list {
  background-color: #0e0e0e;
}
.theme-dark .category-list ul li a {
  color: #ffffff;
}
.theme-dark .related-post-area {
  background-color: #0e0e0e;
}
.theme-dark .paginations ul li a {
  color: #ffffff;
}
.theme-dark .single-stories-card .stories-content {
  background-color: #131212;
}
.theme-dark .single-news-card .news-content {
  background-color: #0e0e0e;
}
.theme-dark .single-news-card .news-content .list ul li a {
  color: #ffffff;
}
.theme-dark .single-news-card.style2 .news-content {
  background-color: #000000;
}
.theme-dark .single-news-card.style3 {
  background-color: #000000;
}
.theme-dark .single-news-card.style3 .news-content {
  background-color: #000000;
}
.theme-dark .news-details .news-simple-card .list ul li a {
  color: #ffffff;
}
.theme-dark .tags-and-share .tags ul li span {
  color: #ffffff;
}
.theme-dark .tags-and-share .tags ul li a {
  color: #ffffff;
}
.theme-dark .tags-and-share .share ul li span {
  color: #ffffff;
}
.theme-dark .reply-area .reply-form .form-group .form-control {
  background-color: #0e0e0e;
  color: #ffffff;
}
.theme-dark .reply-area .reply-form .form-group .form-control::-moz-placeholder {
  color: #ffffff;
}
.theme-dark .reply-area .reply-form .form-group .form-control::placeholder {
  color: #ffffff;
}
.theme-dark .news-slider.owl-theme .owl-nav [class*=owl-] {
  background-color: #424141;
  color: #ffffff;
}
.theme-dark .accordion .accordion-item {
  background-color: #0e0e0e;
}
.theme-dark .accordion .accordion-item .accordion-button {
  color: #ffffff;
}
.theme-dark .accordion .accordion-item .accordion-button::after {
  color: #ffffff;
}
.theme-dark .login {
  background-color: #0e0e0e;
}
.theme-dark .login .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .login .form-group .form-control::-moz-placeholder {
  color: #ffffff;
}
.theme-dark .login .form-group .form-control::placeholder {
  color: #ffffff;
}
.theme-dark .login a {
  color: #ffffff;
}
.theme-dark .register {
  background-color: #0e0e0e;
}
.theme-dark .register .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .register .form-group .form-control::-moz-placeholder {
  color: #ffffff;
}
.theme-dark .register .form-group .form-control::placeholder {
  color: #ffffff;
}
.theme-dark .password {
  background-color: #0e0e0e;
}
.theme-dark .password .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .password .form-group .form-control::-moz-placeholder {
  color: #ffffff;
}
.theme-dark .password .form-group .form-control::placeholder {
  color: #ffffff;
}
.theme-dark .privacy-content .list ul li {
  color: #ffffff;
}
.theme-dark .condition-content .list ul li {
  color: #ffffff;
}
.theme-dark .contacts-form {
  background-color: #0e0e0e;
}
.theme-dark .contacts-form .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .contact-and-address .contact-and-address-content .contact-card {
  background-color: #0e0e0e;
}
.theme-dark .navbar-area.nav-bg-2 .desktop-nav .others-options .icon {
  color: #ffffff;
}
.theme-dark .modal.right .modal-content {
  background-color: #151414;
}
.theme-dark .modal .modal-body .white-logo {
  display: block;
}
.theme-dark .modal .modal-body .main-logo {
  display: none;
}
.theme-dark .modal .modal-body .contact-form #contactForm .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}
.theme-dark .modal .modal-body .contact-form #contactForm .form-group .form-control::-moz-placeholder {
  color: #ffffff;
}
.theme-dark .modal .modal-body .contact-form #contactForm .form-group .form-control::placeholder {
  color: #ffffff;
}
.theme-dark .modal .modal-body .sidebar-contact-info .info-list li {
  color: #ffffff;
}
.theme-dark .modal .modal-body .sidebar-contact-info .info-list li a {
  color: #ffffff;
}
.theme-dark .footer-area {
  background-color: #121111;
}
.theme-dark .copyright-area {
  background-color: #1b1a1a;
}
.theme-dark .admisssion-area.admission-bg::before {
  background: rgba(7, 7, 7, 0.75);
}
@media only screen and (max-width: 767px) {
  .theme-dark .podcast-content {
    background-color: #0e0e0e;
  }
  .theme-dark .slider-content.style2 {
    background-color: transparent;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .theme-dark .slider-content.style2 {
    background-color: transparent;
  }
}
@media only screen and (max-width: 991px) {
  .theme-dark .navbar-area {
    background-color: #000000;
  }
  .theme-dark .navbar-area.nav-bg-3 {
    border-bottom: none;
  }
  .theme-dark .navbar-area.nav-bg-3.is-sticky {
    background: #000000;
  }
  .theme-dark .mobile-responsive-nav .mobile-responsive-menu .logo .white-logo {
    display: block;
  }
  .theme-dark .mobile-responsive-nav .mobile-responsive-menu .logo .main-logo {
    display: none;
  }
  .theme-dark .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav {
    background-color: #000000;
  }
  .theme-dark .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul {
    background-color: #000000;
  }
  .theme-dark .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a {
    border-top-color: #424141;
    color: #ffffff !important;
  }
}/*# sourceMappingURL=dark.css.map */